import firebase from 'firebase';
const firebaseConfig = {
  apiKey: "AIzaSyAZH0zxwYk-GLgBve7YSwSLjYQCZ8Sak_8",
  authDomain: "yokohamasite-92989.firebaseapp.com",
  projectId: "yokohamasite-92989",
  storageBucket: "yokohamasite-92989.appspot.com",
  messagingSenderId: "659149898056",
  appId: "1:659149898056:web:b650c9ac99aaf0b9c7337d",
  measurementId: "G-XW23P1HPMW"
};
firebase.initializeApp(firebaseConfig);
const database = firebase.database();
export const contactsRef = database.ref('contacts');
export const pushContact = ({ content, name, address, phoneNumber, email }) => {
    contactsRef.push({ content, name, address, phoneNumber, email })
}