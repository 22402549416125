import React from 'react';
import '../business.css';

import {Container} from 'react-bootstrap';

const BusinessPart = (props) => {
  return(
    <>
      <Container>
        <div style={{ margin: '0 auto', textAlign: 'center'}}>
          <h3 style={{padding:'10px 0', marginBottom: '10px', fontWeight: 'bold'}}>{props.title}</h3>
          <p style={{borderBottom :'2px solid blue', width: '80px', margin: '20px auto'}}></p>
          <img src={props.src} alt="事業内容画像" className="my-4 business-img" />
          <p>{props.content}</p>
        </div>
      </Container>
    </>
  );
}

export default BusinessPart;