import React from 'react';


const Title = ( props ) => {
  return(
    <div className="title-height">
      <h2>{props.title}</h2>
    </div>
  );
}

export default Title;