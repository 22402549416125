import React from 'react';
import Layout from '../components/Layout/Layout';
import Title from '../components/Title';
import Content from '../components/Content';
import VoiceParts from '../components/VoiceParts';
import MenImage from '../images/men.png';
import WomenImage from '../images/women.png';
import { Container, Row, Col } from 'react-bootstrap';
import FadeIn from 'react-fade-in';

const VoiceLists = [
  {head: '最初から最後まで担当の方が変わらず、途中のプラン変更にも対応してもらえた。', src: MenImage, text: '' },
  {head: '細かい内訳等、丁寧に説明してくれた。', src: MenImage, text: '' },
  {head: '工事後の気になる所に対し連絡をしたら、速やかに対応してくれた。', src: WomenImage, text: '' },
  {head: '色々なパターンの見積もりを出してくれた。', src: MenImage, text: '' },
  {head: '担当の方や職人の方の感じが良く、仕事も丁寧で綺麗に仕上げてくれた。', src: WomenImage, text: '' },
]

const Voice = () => {
  return(
    <Layout>
      <Title title="お客様の声" />
      <Container className="mb-4">
        <FadeIn
          delay="100"
          transitionDuration="2000"
        >
        <Row>
          {VoiceLists.map((list, index) => {
            return(
              <Col key={index} className="px-4" sm={6}>
                <VoiceParts head={list.head} src={list.src} text={list.text} />
              </Col>
              );
          })}
        </Row>
        </FadeIn>
      </Container>
    </Layout>
  );
}

export default Voice;