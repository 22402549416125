import React from 'react';
import Layout from '../components/Layout/Layout';
import News from '../components/News';
import Content from '../components/Content';
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TopImage1 from '../images/top-image1.jpg';
import TopImage2 from '../images/top-image2.jpg';
import TopImage3 from '../images/top-image3.jpg';
import TopImage4 from '../images/top-image4.jpg';


const Top = () => {
  const news = [
    // {date: '2021/02/20', text: '施工事例更新'},
    // {date: '2021/02/18', text: 'HP開設'},
    // {date: '2021/02/18', text: '会社設立'},
  ]
  const setting = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }
  return(
    <Layout>
      <div className="slider-image">
      <Slider {...setting} className="mt-3" >
          <img src={TopImage1} class="w-100"  />
          <img src={TopImage2} class="w-100"  />
          <img src={TopImage3} class="w-100"  />
          <img src={TopImage4} class="w-100"  />
      </Slider>
      </div>
      <div className="slider-content"><p>お住まいのことならどんな些細なことでも<br />お気軽にお問い合わせください。<br />専門スタッフが的確にアドバイスいたします。</p></div>
      <Container className="py-5">
        <Content title="コロナウイルス感染対策" content="弊社ではお客様に安心してリフォームを受けて頂ける様に、感染対策を徹底しております。" style="2px solid red" />
        <div>
          <h2 style={{borderBottom: '2px solid gray', textAlign: 'center', margin: '20px auto', width:'50%'}}>私たちについて</h2>
          <p className="about-us">私たち横浜匠建はリフォーム工事を手掛けるにあたりお客様の「想い」を第一に心がけております。<br />
             住まい造りはお客様にとって大きな買い物であり、そんなお買い物を託して頂いたお客様の為に我々はその想いになんとしてでも答えていかなくてはなりません。<br />
             壊れた箇所の修復、古くなった物の交換、より快適な環境作りなど各お客様によってプランやデザイン、品質へのこだわりといった様々なご要望があるかと思います。<br />
             そのような想いに全て答えていけるよう弊社では専門の担当者がお客様の思い描いているご希望に添えるご提案や徹底した施工管理を一貫体制で行なっており、各担当者が現場の状況をしっかりと把握し、職人やメーカーと繋ぎ合わせ皆で「想い」を共有して手掛けていけるよう責任を持って請け負う事を社員一同心がけております。
          </p>
        </div>
        <h2 style={{borderBottom: '2px solid gray', textAlign: 'center', margin: '20px auto', width:'50%'}}>NEWS</h2>
        <div style={{border: '1px solid black', padding: '15px'}}>
          {news.map((c, index) => {
            return <News key={index} text={c.text} date={c.date} />
          })}
        </div>
      </Container>
    </Layout>
  );
}

export default Top;