import React from 'react';
import '../style.css';

import { Container } from 'react-bootstrap';
const Content = ( props ) => {
  // const [ title, content ] = props;
  return(
    <>
      <Container>
        <div className="content-style">
          <h4>{props.title}</h4>
          <p style={{border: props.style}}>{props.content}</p>
        </div>
      </Container>
    </>
  );
}

export default Content;