import React, {useState} from 'react';
import { Row, Col } from 'react-bootstrap';


const ContactInputField = ({setValue, name, placeholder, isRequired, setIsError, maxLength}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const isError = errorMessage !== '';
  const errorMessageColor = {
    color: 'red'
  };

  return(
    <>
      <Row className="border">
        <Col className="border-right position-relative" sm="3">
          <p>{name}</p>
          {isRequired && 
            <span className="required mt-2">必須</span>
          }
        </Col>
        <Col sm="9">
          <input
            name="name"
            placeholder={placeholder}
            className="w-100 my-3"
            onChange={
              (e) => {
                const value = e.target.value
                setValue(value)
                if (isRequired && value === '') {
                  setIsError(true);
                  setErrorMessage(`${name}は必須です`);
                } else if(maxLength !== undefined && maxLength < value.length) {
                  setIsError(true);
                  setErrorMessage(`${name}は${maxLength}以内で入力してください。`)
                }else {
                  setIsError(false);
                  setErrorMessage('');
                }
              }
            }
          />
          {isError && 
            <span style={errorMessageColor}>{errorMessage}</span>
          }
        </Col>
      </Row>
    </>
  );
}

export default ContactInputField;