import React from 'react';
import Layout from '../components/Layout/Layout';
import Title from '../components/Title';
import Content from '../components/Content';
import { Container } from 'react-bootstrap';
import '../company.css';

const lists = [
  {item: '会社名', value: '横浜匠建株式会社'},
  {item: '代表取締役', value: '三村 洪人'},
  {item: '所在地', value: '〒224-0032 神奈川県横浜市都筑区茅ヶ崎中央20-13 泉ビル1F'},
  {item: 'TEL/FAX', value: '045-532-3107/045-532-3108'},
  {item: '資本金', value: '100万円'},
  {item: '事業内容', value: 'リフォーム事業'},
]

const companyInfo = [
  {title: '住所　　' , content: '〒224-0032 神奈川県横浜市都筑区茅ヶ崎中央20-13 泉ビル1F'},
  {title: '最寄駅　' , content: '「センター南駅」より、徒歩5分'},
  {title: '営業時間' , content: '9:00〜18:00'},
  {title: '定休日　' , content: '日曜・祝日'},
]

const Company = () => {
  return(
    <Layout>
      <Title title="会社概要" />
      <Container>
        <table className="table">
          <tbody>
            {lists.map((list, index) => {
              return(
                <tr key={index}>
                  <th>{list.item}</th>
                  <td>{list.value}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <h4 className="text-center mt-4">アクセス</h4>
        <div className="access-map mt-4">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3246.429527691456!2d139.57005691519728!3d35.54309274514299!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f62d91776787%3A0x611afa80ffcf518f!2z44CSMjI0LTAwMzIg56We5aWI5bed55yM5qiq5rWc5biC6YO9562R5Yy66IyF44Kx5bSO5Lit5aSu77yS77yQ4oiS77yR77yT!5e0!3m2!1sja!2sjp!4v1613659699089!5m2!1sja!2sjp" width="600" height="450" frameborder="0" style={{border:"0"}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
          {companyInfo.map((info, index) => {
            return(
              <div className="company-info">
                <span style={{fontWeight: 'bold', paddingRight: '8px'}} key={index}>
                  {info.title}
                </span>
                <p>{info.content}</p>
              </div>
            );
          })}
        </div>
      </Container>
    </Layout>
  );
}

export default Company;