import React from 'react';
import { Card } from 'react-bootstrap';
import '../case.css';


const CaseExample = ({before, after}) => {
  return(
    <div className="case-list-container">
      <Card>
        <Card.Body className="case-before">
          <Card.Img variant="top" src={before} alt="aaa" />
          <Card.Title>Before</Card.Title>
        </Card.Body>
      </Card>
      <span>▶︎</span>
      <Card>
        <Card.Body className="case-after">
          <Card.Img variant="top" src={after} alt="aaa" />
          <Card.Title>After</Card.Title>
        </Card.Body>
      </Card>
    </div>
  );
}

  

export default CaseExample;