import React from 'react';
import '../style.css';

const FooterMenu = (props) => {
  return(
    <>
      <ul>
        <div className="footer-menu-title">
          <li >{props.name}</li> 
        </div>
        
        {props.list.map((list, index) => {
          return(
            <li className="mb-1" key={index}>{list}</li>
          );
        })} 
      </ul>
    </>
  );
}
export default FooterMenu;