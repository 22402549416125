import React from 'react';
import Layout from '../components/Layout/Layout';
import Title from '../components/Title';
import CaseExample from '../components/CaseExample';
import Content from '../components/Content';
import Before1 from '../images/before1.jpg';
import After1 from '../images/after1.jpg';
import Before2 from '../images/before2.jpg';
import After2 from '../images/after2.jpg';
import Before3 from '../images/before3.jpg';
import After3 from '../images/after3.jpg';
import Before4 from '../images/before4.jpg';
import After4 from '../images/after4.jpg';
import Before5 from '../images/before5.jpg';
import After5 from '../images/after5.jpg';
import Before6 from '../images/before6.jpg';
import After6 from '../images/after6.jpg';

const casesBefore = [
  {before: Before1, after: After1},
  {before: Before2, after: After2},
  {before: Before3, after: After3},
  {before: Before4, after: After4},
  {before: Before5, after: After5},
  {before: Before6, after: After6}
]

const Case = () => {
  return(
    <Layout>
      <Title title="施工事例" />
      <div>
      {casesBefore.map((c, index) => {
        return <CaseExample key={index} before={c.before} after={c.after} />
      })}
      </div>
    </Layout>
  );
}

export default Case;