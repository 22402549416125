import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import FadeIn from 'react-fade-in';
import '../style.css'
import Logo from '../images/logo.png';

const menus = [
  {path: '/company', name: '会社概要'　     , style: 'header-menu'},
  {path: '/case'   , name: '施工事例' 　    , style: 'header-menu'},
  {path: '/flow'   , name: 'リフォームの流れ', style: 'header-menu'},
  {path: '/voice'  , name: 'お客様の声'     , style: 'header-menu'},
  {path: '/business'  , name: '事業内容'     , style: 'header-menu'},
  // {path: '/access' , name: 'アクセス'   　  , style: 'header-menu'},
  {path: '/contact', name: 'お問い合わせ'},
]

const Header = () => {
  const [ menuOpen, setMenuOpen ] = useState(false);
  const onClickMenuOpen = () => {
    if (menuOpen === true) {
      setMenuOpen(false);
    }else {
      setMenuOpen(true);
    }
  }
  return(
    <header>
      <div>
        <h2 className={menuOpen ? "d-none" : "d-block"}><Link to="/"><img src={Logo} style={{width: '75px', height: '80px', margin: '20px 20px 0px 0px'}}  /></Link></h2>
        <div className="hamburger-menu">
          {/* ハンバーガーメニュー */}
          <button 
            className={menuOpen ? "menu-open-btn" : "menu-close-btn"}
            onClick={() => {
              onClickMenuOpen();
            }}
          >
            <span></span>
          </button>
          {/* ここまで */}

          {/* 中身 */}
          <div className={menuOpen ? "hamburger-menu-list" : "d-none"}>
            <ul className="open-menu-list px-3 mt-5">
                {menus.map((menu, index) => {
                  return(
                      <li key={index}>
                        <Link to={menu.path} style={{color: 'white'}}>{menu.name}</Link>
                        <span><FontAwesomeIcon icon={faAngleRight} style={{color: 'white'}} /></span>
                      </li>
                  );
                })}
            </ul>
          </div>
          {/* ここまで */}
        </div>
    
        <ul className="header-menu-list">
          {menus.map((menu, index) => {
            return(
              <li className={menu.style} key={index}>
                <Link to={menu.path}>{menu.name}</Link>
              </li>
            );
          })}
        </ul>
      </div>
    </header>
  );
}

export default Header;