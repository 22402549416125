import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import Layout from '../components/Layout/Layout';
import Title from '../components/Title';
import ContactInputField from '../components/ContactInputField';
import ContactTextArea from '../components/ContactTextArea';
import Modal from '../components/Modal';
import ContactConfirmItem from '../components/ContactConfirmItem';
import ContactTitle from '../components/ContactTitle';
import {pushContact} from '../firebase';
import { Row, Col } from 'react-bootstrap';
import '../contact.css'

const Contact = () => {
  const history = useHistory()

  const [content, setContent] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');

  const [isErrorContent, setIsErrorContent] = useState(true);
  const [isErrorName, setIsErrorName] = useState(true);
  const [isErrorAddress, setIsErrorAddress] = useState(false);
  const [isErrorPhoneNumber, setIsErrorPhoneNumber] = useState(true);
  const [isErrorEmail, setIsErrorEmail] = useState(true);

  const [isAgree, setIsAgree] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const isValidation = isErrorContent || isErrorName || isErrorAddress || isErrorPhoneNumber || isErrorEmail || !isAgree
  


  return(
    <Layout>
      <Title title="お問い合わせ" />
      <div className="contact-top py-5">
        <div className="contact-title">
          <h2>お問い合わせフォーム</h2>
        </div>
        <div className="contact-form">
          <p className="sp-contact-content">
              お客さまからのご質問をお問い合わせフォームにて受け付けております。<br />
              必要事項をご記入の上、「入力内容の確認画面へ」を押してください。
          </p>
          <ContactTitle title="お問い合わせ内容を入力してください。" />
          <ContactTextArea
            name="お問い合わせ内容"
            setValue={setContent}
            isRequired={true}
            setIsError={setIsErrorContent}
            
          />
          <ContactTitle title="お客様情報を入力してください。" />
          <ContactInputField
            name="名前"
            placeholder="例）山田 太郎"
            setValue={setName}
            isRequired={true}
            setIsError={setIsErrorName}
            maxLength = {20}
          />
          <ContactInputField
            name="住所"
            placeholder="例）東京都港区六本木1-2-3 港区ビル1002号室"
            setValue={setAddress}
            isRequired={false}
            setIsError={setIsErrorAddress}
            maxLength = {50}
          />
          <ContactInputField
            name="電話番号"
            placeholder="例）080-1234-5678"
            setValue={setPhoneNumber}
            isRequired={false}
            setIsError={setIsErrorPhoneNumber}
            maxLength = {13}
          />
          <ContactInputField
            name="メールアドレス"
            placeholder="例）yokohama@sample.com"
            setValue={setEmail} 
            isRequired={true}
            setIsError={setIsErrorEmail}
            maxLength = {255}
          />
          <ContactTitle title="個人情報の取り扱いについて" />
          <p>下記事項をご確認の上、同意していただける場合は[同意する]にチェックを入れてください。</p>
          <Row>
            <Col sm="12">
            <textarea className="person-info-content" name="content">
              横浜匠建株式会社（以下「当社」）は、以下のとおり個人情報保護方針を定め、個人情報保護の重要性の認識と取組みを徹底し、個人情報の保護を推進致します。
              個人情報の管理
              当社は、お客様の個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備取り、安全対策を実施し個人情報の厳重な管理を行ないます。
              個人情報の利用目的
              お客さまからお預かりした個人情報は、当社からのご連絡・業務のご案内やご質問に対する回答として、電子メールや資料のご送付に利用いたします。
              個人情報の第三者への開示や提供の禁止
              当社は、お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。
              •お客さまの同意がある場合
              •お客さまが希望されるサービスを行なうために当社が業務を委託する業者に対して開示する場合
              •法令に基づき開示することが必要である場合
              個人情報の安全対策
              当社は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。
              ご本人の照会
              お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、ご対応させていただきます。
              法令、規範の遵守と見直し
              当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。
              お問い合せ
              当社の個人情報の取扱に関するお問い合せは下記までご連絡ください。
              横浜匠建株式会社
              〒224-0032
              神奈川県横浜市都筑区茅ヶ崎中央20-13泉ビル1F
              TEL:045-532-3107
              FAX:045-532-3108
              MAIL:yokohamasyoken@gmail.com
            </textarea>
            </Col>
            <div className="person-info-agree">
              <input 
                type="checkbox"
                onChange={
                  (e) => {
                      setIsAgree(!isAgree);
                      }
                  }
              />
              <span className="pl-3">個人情報の取扱いに同意する</span>
            </div>
          </Row>
          <p className="text-center">
            [入力内容の確認画面へ] ボタンをクリックして入力内容のご確認をお願いします。<br />
            ご入力、誠にありがとうございました。
          </p>
          <div className="text-center mt-5">
            <button
              type="button"
              className={isValidation ? "person-info-agree-disable-btn" : "person-info-agree-btn"}
              onClick={() => {
                if(isValidation) { return; }
                  setIsOpen(true);
              }}
            >
              入力内容の確認画面へ
            </button>
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <h3>お問い合わせ内容の確認</h3>
        <ContactConfirmItem name='お問い合わせ内容' value={content} />
        <ContactConfirmItem name='お名前' value={name} />
        <ContactConfirmItem name='ご住所' value={address} />
        <ContactConfirmItem name='お電話番号' value={phoneNumber} />
        <ContactConfirmItem name='メールアドレス' value={email} />
        <div className="text-center mt-5">
          <button
            type="button"
            className={isValidation ? "person-info-agree-disable-btn" : "person-info-agree-btn"}
            onClick={() => {
              if(isValidation) { return; }
              pushContact({content, name, address, phoneNumber, email})
              history.push('/contact/complate')
            }}
          >
            お問い合わせ送信
          </button>
        </div>
      </Modal>
    </Layout>
  );
}

export default Contact;