import React from 'react';
import FooterMenu from './FooterMenu';
import '../style.css';

const menu1 = [
  '1都3県対応可能', 'その他地域も対応可能ですので、一度ご連絡ください。', 'リフォームの流れ'
]

const menu2 = [
  'TOTO', 'panasonic', 'LIXIL', 'クリナップ', 'タカラスタンダード', 'Rinnai', 'NORITZ', '大建工業', 'YKKAP', 'ケイミュー', 'ニチハ', 'アイジー工業', '稲垣商事', '日本ペイント' ,'関西ペイント', 'エスケー化研', 'etc'
]

const menu3 = [
  'LINE', 'instagram'
]

const Footer = () => {
  const date = new Date();
  return(
    <footer>
      <div>
        <ul className="footer-menu-list">
          <li className="footer-contents-list"><FooterMenu
               name="対応地域"
               list={menu1}
               />
          </li>
          <li className="footer-contents-list"><FooterMenu
               name="取扱メーカー"
               list={menu2}
               />
          </li>
          <li className="footer-contents-list"><FooterMenu
               name="各種SNS"
               list={menu3}
               />
          </li>
        </ul>
      </div>
      <div style={{fontSize: '12px', textAlign: 'center'}} className="py-4">
        ©︎{date.getFullYear()} 横浜匠建株式会社 All Rights Reserved.
      </div>
    </footer>
  );
}

export default Footer;