import React, { useEffect, useState } from 'react';
import { contactsRef } from '../firebase';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';



const Admin = () => {
  const [contacts, setContacts] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [password, setPassword] = useState("");
  const adminPassword = "Yokohama01";

  useEffect(() => {
    contactsRef
        .orderByKey()
        .limitToLast(15)
        .on('value', (snapshot) => {
            const contacts = snapshot.val();
            if (contacts === null) return;
            const entries = Object.entries(contacts);
            const newContacts = entries.map((entry) => {
                const [key, content] = entry;
                return { key, ...content }
            });
            setContacts(newContacts);
    });
  }, []);
  const contactList = (
    <div>
      {contacts.map(({ key, content, name, address, phoneNumber, email }) => {
        return (
            <div key={key} style={{borderBottom: '1px solid black', padding: '10px 0'}}>
              <p>名前：{name}</p>
              <p>メールアドレス：{email}</p>
              <p>電話番号：{phoneNumber}</p>
              <p>住所：{address}</p>
              <p>お問い合わせ内容：<br />{content}</p>
            </div>
        );
      })}
    </div>
  );
  const signIn = (
    <div>
      <input 
        onChange={(e) => setPassword(e.target.value)}
        type="text"
      />
      <button
        onClick={() => {
          if(adminPassword === password){
            setIsAdmin(true);
          }
        }}
        type="submit"
      >パスワード入力</button>
    </div>
  );
  if (isAdmin) {
    return(
      <Container className="my-5">
        {contactList}
        <Link to="/">サイトに戻る ＞</Link>
      </Container>
    );
  } else {
    return(
    <Container className="my-5">
      {signIn}
    </Container>
    );
  }
}

export default Admin;