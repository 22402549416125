import React from 'react';

const News = ({ date, text }) => {
  return(
    <>
      <div style={{borderBottom: '1px solid #f3f3f3', padding: '10px 0'}}>
       {date}【{text}】
      </div>
    </>
  );
}

export default News;