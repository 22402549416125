import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import 'bootstrap/dist/css/bootstrap.min.css';


const Layout = ({children}) => {
  return(
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
}

export default Layout;