import React from 'react';
import Layout from '../components/Layout/Layout';
import Content from '../components/Content';
import BusinessPart from '../components/BusinessPart';
import Title from '../components/Title';
import Business1 from '../images/business1.jpg';
import Business2 from '../images/business2.jpg';
import Business3 from '../images/business3.jpg';
import Business4 from '../images/business4.jpg';
import Business5 from '../images/business5.jpg';
import Business6 from '../images/business6.jpg';
import Business7 from '../images/business7.jpg';
import Business8 from '../images/business8.jpg';
import Business9 from '../images/business9.jpg';
import '../business.css';

const Business = () => {
  const business = [
    { src: Business1, title: '大工工事', content: '改築、増改築、建具交換、間取り変更、バリアフリー等' },
    { src: Business2, title: '内装工事', content: 'クロス、CF、畳、襖、カーペット、フローリング等' },
    { src: Business3, title: '設備、配管工事', content: '給水、給湯、排水配管、トイレ、洗面、浴室、キッチン等その他設備機器取付け' },
    { src: Business4, title: '電気工事', content: '配線、幹線、照明移設等' },
    { src: Business5, title: 'サッシ工事', content: 'ガラス、カバー工法、玄関扉、窓新設等' },
    { src: Business6, title: '左官工事', content: '外壁補修、土間等' },
    { src: Business7, title: '外構工事', content: '外構ブロック、門扉、フェンス等' },
    { src: Business8, title: '塗装工事', content: '外壁、屋根塗装、防水塗装、屋内塗装等' },
    { src: Business9, title: '屋根工事', content: '雨桶交換、板金交換、カバー工法、葺き替え等' }
  ]
  return(
    <Layout>
      <Title title="事業内容" />
      <div className="py-5">
        {business.map((business, index) => {
          return <BusinessPart key={index} src={business.src} title={business.title} content={business.content} />
        })}
      </div>
    </Layout>
  );
}

export default Business;