import React from 'react';
import Layout from '../components/Layout/Layout';
import Title from '../components/Title';
import Content from '../components/Content';
import FlowPart from '../components/FlowPart';
import { faStethoscope, faPhone, faSearch, faUserFriends, faHandshake, faTools, faHome } from '@fortawesome/free-solid-svg-icons';
import { faClipboard, faCheckCircle } from '@fortawesome/free-regular-svg-icons';


const flowLists = [
  {
    color: 'green', number: '1', text: '現地調査',
    content: '1～2 名のスタッフが現場へ向かいます。 現場到着後、お客様と施工箇所の確認、お打ち合わせをさせて頂き現地確認の方をさせて 頂きます。所要時間はおよそ 30 分～1 時間程度です。 事前にご準備、ご用意していただく物などはございません。',
    icon: faStethoscope
  },
  {
    color: 'red', number: '2', text: 'お見積りプラン作成',
    content: '現地調査させていただいた内容を元にお見積書を作成させて頂きます。',
    icon: faClipboard
  },
  {
    color: 'blue', number: '3', text: 'お打ち合わせ',
    content: 'お見積書の内訳等を細かくご説明させて頂きます。また追加のご要望や内容の変更などご ざいましたらお気軽にお申し付け下さい。再度お見積書をお作りさせて頂きます。',
    icon: faUserFriends
  },
  {
    color: 'orange', number: '4', text: 'ご成約・工事日程日決め',
    content: '最終的なお打ち合わせをさせて頂き再度内容、金額等のご確認をさせて頂きご納得頂いた 上、ご成約となります。 工事の日程、段取りなども同時にご相談させて頂きます。 また、工事の内容によっては事前にご近隣の方へのご挨拶等もさせて頂きます。',
    icon: faHandshake
  },
  {
    color: '#e8d3d1', number: '5', text: '着工',
    content: '事前のお打ち合わせの内容に沿って担当者の徹底した施工管理の元、工事を進めさせてい ただきます。 長期工事等の際は工程表をお渡しさせて頂きお客様にも進捗状況をご共有させて頂きなが ら安全第一で工事の方を進めさせていただきます。',
    icon: faTools
  },
  {
    color: '#006888', number: '6', text: '現場管理',
    content: '作業員への指示出し現場進行中のこまめなチェック等、随時担当者によりさせていただきます。',
    icon: faSearch
  },
  {
    color: '#e4007f', number: '7', text: '完工',
    content: '工事が終わりましたら担当者による最終確認の後、お客様お立ち合いの元、施工箇所のご 確認をご一緒にさせて頂き工事完了、お引渡しとなります。',
    icon: faHome
  },
  {
    color: '#ffc0cb', number: '8', text: 'アフターフォロー',
    content: '万一、未了工事や不具合等が発生した場合は項目をご確認させて頂いた上、早急にご対応 させて頂きます。',
    icon: faCheckCircle
  },
]

const last = flowLists.slice(-1)[0];

const Flow = () => {
  return(
    <Layout>
      <Title title="リフォームの流れ" />
      <Content title="リフォームまでの流れ" content="こちらではリフォームの流れを簡単に説明させていただきます。"  style="1px solid black"/>
      {flowLists.map((list, index) => {
        if (last.number === list.number) {
          return <FlowPart key={index} color={list.color} number={list.number} text={list.text} style="d-none" content={list.content} icon={list.icon} />
        }else {
          return (
            <FlowPart key={index} color={list.color} number={list.number} text={list.text} style="right-arrow" content={list.content} icon={list.icon} />
          );
        }
      })}
  
    </Layout>
  );
}

export default Flow;