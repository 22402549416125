import React from 'react';
import '../media.css';


const ContactTitle = (props) => {
  return(
    <>
      <h2 className="contact-content" style={{borderLeft: '8px solid #16a085'}}><span>{props.title}</span></h2>
    </>
  );
}

export default ContactTitle;