import React, {useState} from 'react';
import {Row, Col} from 'react-bootstrap';

const ContactTextArea = ({setValue, name, placeholder, isRequired, setIsError, maxLength}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const isError = errorMessage !== ''; 
  const errorMessageColor = {
      color: 'red'
  };
  return(
    <>
      <Row className="border sp-row">
        <Col className="border-right" sm="3">
          <p>{name}</p>
          {isRequired && 
            <span className="required mt-2">必須</span>
          }
        </Col>
        <Col sm="9">
          <textarea
            className="ta-contact-content"
            onChange={
              (e) => {
                const value = e.target.value
                setValue(value)
                if (isRequired && value === '') {
                  setIsError(true);
                  setErrorMessage(`${name}は必須です`);
                } else if(maxLength !== undefined && maxLength < value.length) {
                  setIsError(true);
                  setErrorMessage(`${name}は${maxLength}以内で入力してください。`)
                }else {
                  setIsError(false);
                  setErrorMessage('');
                }
              }
            }
          ></textarea>
          {isError && 
            <span style={errorMessageColor}>{errorMessage}</span>
          }
        </Col>
      </Row>
    </>
  );
}

export default ContactTextArea;