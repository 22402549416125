import React from 'react';
import '../voice.css';

const VoiceParts = (props) => {
  return(
    <>
      <p className="voice-contents">{props.head}</p>
      <div className="d-flex pb-4 voice-list">
        <img src={props.src} />
        <span>{props.text}</span>
      </div>
    </>
  );
}

export default VoiceParts;