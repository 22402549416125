import React from 'react';
import Layout from '../components/Layout/Layout';
import { Container } from 'react-bootstrap';


const ContactComplate = () =>{
  
  return(
    <Layout>
        <Container>
            <h2 className="text-center py-5">お問い合わせありがとうございます。</h2>
            <div className="text-center pb-5">
                <p>お問い合わせが正常に送信されました</p>
                <p>後日、担当者から連絡させていただきますので、今しばらくお待ちください。</p>
            </div>
        </Container>
    </Layout>
  );
}

export default ContactComplate;