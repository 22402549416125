import React from 'react';

const Modal = ({children, isOpen, setIsOpen}) => {
    const modalStyle = {
        overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "rgba(0,0,0,0.85)",
            zIndex: 9998,
            height: '100%',
            width: '100%'
        },
        content: {
            backgroundColor: 'white',
            width: '60%',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
            padding: '3%'
        },
        closeButton: {
            textAlign: 'right',
            fontSize: '1.5em',
            cursor: 'pointer'
        }
    }
    return(
        <>
            {isOpen &&
                <div style={modalStyle.overlay}
                    onClick={() => {
                        setIsOpen(false);
                    }}
                >
                    <div style={modalStyle.content}>
                        <div style={modalStyle.closeButton}>
                            <span>✖︎</span>
                        </div>
                        {children}
                    </div>
                </div> 
            }
        </>
    );
}
export default Modal;