import React from 'react';
import Layout from '../components/Layout/Layout';
import Title from '../components/Title';

const Access = () => {
  return(
    <Layout>
      <Title title="アクセス" />
    </Layout>
  );
}

export default Access;