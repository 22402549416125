import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import '../flow.css';


const FlowPart = ({color, number, text, style, content, icon }) => {
  
  return(
    <>
      <div className="flow-main" style={{backgroundColor: color, diplay: 'flex', margin:'15px auto', padding: '15px 30px'}} >
        <div className="flow-contents">
          <div className="text-center">
            <p className="m-0">STEP</p>
            <h4 style={{color: color}} className="flow-number">{number}</h4>
          </div>
          <span>{text}</span>
          <FontAwesomeIcon  style={{fontSize: '22px', color: 'white'}} icon={icon} />
        </div>
        <p style={{color: 'white'}}>{content}</p>
      </div>
      <span style={{textAlign: 'center'}} className={style}>⬇︎</span>
    </>
  );
}

export default FlowPart;