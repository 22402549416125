import React from 'react';

const ContactConfirmItem = ({name, value}) => {
    const style = {
        row: {
            padding: '10px',
            borderLeft: '8px solid #16a085',
            display: 'flex',
            marginTop: '20px'
        },
        title: {
            width: '20%'
        },
        value: {
            width: '80%'
        }
    }
    return(
        <div style={style.row}>
            <div style={style.name}>{name}</div>
            <div style={style.value}>{value}</div>
        </div> 
    );
}
export default ContactConfirmItem;