import Top from './pages/Top';
import Company from './pages/Company';
import Case from './pages/Case';
import Flow from './pages/Flow';
import Voice from './pages/Voice';
import Access from './pages/Access';
import Contact from './pages/Contact';
import Business from './pages/Business';
import ContactComplate from './pages/ContactComplate';
import Admin from './pages/Admin';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';


function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={Top} />
          <Route exact path="/company" component={Company} />
          <Route exact path="/case" component={Case} />
          <Route exact path="/flow" component={Flow} />
          <Route exact path="/voice" component={Voice} />
          <Route exact path="/business" component={Business} />
          <Route exact path="/access" component={Access} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/contact/complate" component={ContactComplate} />
          <Route exact path="/admin" component={Admin} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
